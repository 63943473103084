/*Hide short text by default (resolution > 1200px)*/
.short-text {
	display: none;
}

/*When resolution <= 800px, hide full text and show short text*/
@media (max-width: 800px) {
	.short-text {
		display: inline-block;
	}
	.full-text {
		display: none;
	}
}
